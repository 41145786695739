import { defineStore } from 'pinia';

export const useModalsStore = defineStore('modals', {
    state: (): Partial<any> => ({
        modals: [
            { name: 'session', active: false, type: '' },
            { name: 'phone', active: false, type: '' },
            { name: 'notapproved', active: false, type: '' },
            { name: 'voice-recog', active: false, type: '' },
            { name: 'face-recog', active: false, type: '' },
        ]
    }),
    actions: {
        getModal(name: string) {
            const modal = this.modals.find((modal: any) => modal.name === name);
            return this.modals.find((modal: any) => modal.name === name);
        },
        async toggleModal(name: string, type?: string) {
            this.closeAllModals();
            const modal = this.modals.find((modal: any) => modal.name === name);
            modal.active = !modal.active;
            modal.type = type;
        },
        async closeModal(name: string) {
            const modal = this.modals.find((modal: any) => modal.name === name);
            modal.active = false;
            modal.type = '';
        },
        async closeAllModals() {
            this.modals.map((modal: any) => (modal.active = false, modal.type = ''));
        }
    },
    getters: {
        hasActiveModals(state: any) {
            return state.modals ? state.modals.some((modal: any) => modal.active === true) : false;
        }
    }
});
